import "./src/styles/global.css"
import "./static/BigCityGrotesquePro/stylesheet.css"
import "./static/Massilia/massilia.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

 
//push event Change_Page
export const onRouteUpdate = () => {
    if (typeof window !== 'undefined' && window.dataLayer !== undefined) {
        window.dataLayer.push({ ecommerce: null });
        
        window.dataLayer.push({
            'event': 'change_page'
            });

    }
  }